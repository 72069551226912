import {StoryblokComponent} from '@storyblok/react'
import {HeaderBlock, HeaderType} from 'components/Content/Header'
import {Analytics} from 'lib/analytics'
import Image from 'next/image'
import Link from 'next/link'
import {useRouter} from 'next/router'
import React, {useEffect} from 'react'
import {StoryblokStory} from 'storyblok-generate-ts'
import {
  BlogArticleStoryblok,
  CategoryStoryblok,
  ContentPageStoryblok,
  HeroImageSectionStoryblok,
  HeroSectionStoryblok,
  SectionStoryblok,
} from 'types/storyblok-types'
import UTCFormat from 'util/UTCtoString'

type BlogArticlePropsType = {
  blok: BlogArticleStoryblok
  publishedAt: string
}

const BlogArticle = ({blok, publishedAt}: BlogArticlePropsType) => {
  const router = useRouter()

  useEffect(() => {
    Analytics.getInstance().sendPageView(
      blok.title,
      ['blog', 'post'],
      200,
      'content detail',
      router.locale,
    )
  }, [blok.title, router.locale])

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <div className="relative mb-5 h-80 w-full lg:mb-[50px]">
          <Image
            src={blok.hero_image?.filename}
            alt={blok.hero_image?.alt ? blok.hero_image?.alt : ``}
            fill
            className="object-cover"
          />
        </div>
        <div className="w-full max-w-3xl px-5 lg:px-0">
          <span className="text-sm font-semibold text-dats-s6">
            {blok.blog_categories &&
              blok.blog_categories?.map((category, index) => (
                <span key={index}>
                  {(category as StoryblokStory<CategoryStoryblok>).name}
                  {blok.blog_categories &&
                  index !== blok.blog_categories.length - 1 ? (
                    <span className="mx-1">|</span>
                  ) : null}
                </span>
              ))}

            <span>{' • '}</span>
            {UTCFormat(publishedAt)}
          </span>
          <HeaderBlock
            title={blok.title}
            type={HeaderType.h1}
            withoutBottomMargin={true}
          />
        </div>
        <div className="w-full [&>section>div]:max-w-3xl">
          {blok.content_block
            ? blok.content_block.map((nestedBlok: any, index) => {
                const arrayOfHeroIndexesWithRoundedBorder: number[] = []

                const pushIndexToArray = (i: number) => {
                  arrayOfHeroIndexesWithRoundedBorder.push(i)
                }

                blok.content_block?.forEach(
                  (
                    content:
                      | SectionStoryblok
                      | HeroSectionStoryblok
                      | HeroImageSectionStoryblok,
                    index: number,
                  ) => {
                    if (
                      (content.component === 'hero_section' ||
                        content.component === 'hero_image_section') &&
                      content.bottom_rounded_overlay === true
                    ) {
                      pushIndexToArray(index + 1)
                    }
                  },
                )
                return (
                  <StoryblokComponent
                    blok={nestedBlok}
                    key={nestedBlok._uid}
                    sectionAfterRoundedHero={arrayOfHeroIndexesWithRoundedBorder.includes(
                      index,
                    )}
                    noTopMargin={true}
                  />
                )
              })
            : null}
        </div>
        {blok.related_articles ? (
          <section className="flex w-full justify-center px-5 lg:px-[100px]">
            <div className="border-bg-dats-s9 mt-20 w-full border-t">
              {blok.title_related_articles ? (
                <div className="mt-20">
                  <HeaderBlock
                    title={blok.title_related_articles}
                    type={HeaderType.h3}
                  />
                </div>
              ) : null}
              <div className="mb-12 mt-[50px] flex w-full flex-col justify-center gap-10 md:flex-row">
                {blok.related_articles?.map(related_article => {
                  const article = related_article as
                    | StoryblokStory<BlogArticleStoryblok>
                    | StoryblokStory<ContentPageStoryblok>
                  return (
                    <Link
                      data-tms-content-type="article"
                      data-tms-content-template="blog"
                      href={`/${article.full_slug}`}
                      className="flex gap-5 no-underline md:flex-col md:gap-0 md:pl-4 lg:w-1/3 lg:pl-0"
                      key={article.id}
                    >
                      <div className="relative size-[70px] shrink-0 md:h-52 md:w-full">
                        {article.content?.teaser_image ? (
                          <Image
                            src={article.content.teaser_image.filename}
                            alt={article.content?.title ?? ''}
                            fill
                            className="rounded-dats object-cover"
                          />
                        ) : null}
                      </div>
                      <div className="md:mt-4">
                        <div className="mb-2 mr-2 flex flex-wrap text-sm text-dats-s6">
                          {article.content?.blog_categories
                            ? (
                                article.content
                                  .blog_categories as StoryblokStory<CategoryStoryblok>[]
                              ).map((blogCategory, index) => (
                                <span className=" " key={blogCategory.id}>
                                  {blogCategory.content.title}
                                  {index !==
                                  article.content.blog_categories.length - 1 ? (
                                    <span className="mx-1">|</span>
                                  ) : null}
                                </span>
                              ))
                            : null}
                          {article.content?.blog_categories &&
                          article.content?.blog_categories.length > 0 ? (
                            <span className="mx-1">{' • '}</span>
                          ) : null}
                          <span>{UTCFormat(article.published_at)}</span>
                        </div>
                        <HeaderBlock
                          type={HeaderType.h3}
                          title={article.content?.title ?? ''}
                        />
                      </div>
                    </Link>
                  )
                })}
              </div>
            </div>
          </section>
        ) : null}
      </div>
    </>
  )
}

export default BlogArticle
